<template>
  <div class="menu">
    <r-row :wrap="false" type="nav" class="px-4">
      <r-col is-width-content
             v-for="item in menu"
             :key="item.title"
             :cols="{ widest: 4, wide: 4, middle: 2, narrow: 2 }"
             class="px-2"
      >
        <r-navigation-tile :to="item.link"
                           :title="item.title"
                           :image="item.image ? item.image : getImage(item.link)"
                           style="width: 153px;"
        />
      </r-col>
    </r-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Menu",

  computed: {
    ...mapGetters(['GET_NAVIGATION',]),

    menu() {
      if (!this.$currentConfig.isTransportOn) {
        return this.GET_NAVIGATION.filter(item => item.link !== '/transport');
      }
      return this.GET_NAVIGATION;
    }
  },

  methods: {
    getImage(link) {
      const regionPath = `/${process.env.VUE_APP_CURRENT_REGION}`;
      const defaultPath = '/default';
      
      switch(link) {
        case '/events':
          try {
            try {
              try {
                return require(`@/assets/navigation${regionPath}/events.png`);
              } catch (e) {
                return require(`@/assets/navigation${regionPath}/events.jpg`);
              }
            } catch (e) {
              return require(`@/assets/navigation${defaultPath}/events.jpg`);
            }
          } catch (e) {
            return '';
          }
        case '/excursions':
          try {
            try {
              try {
                return require(`@/assets/navigation${regionPath}/excursions.png`);
              } catch (e) {
                return require(`@/assets/navigation${regionPath}/excursions.jpg`);
              }
            } catch (e) {
              return require(`@/assets/navigation${defaultPath}/excursions.jpg`);
            }
          } catch (e) {
            return '';
          }
        case '/places':
          try {
            try {
              try {
                return require(`@/assets/navigation${regionPath}/places.jpg`);
              } catch (e) {
                return require(`@/assets/navigation${regionPath}/places.png`);
              }
            } catch (e) {
              return require(`@/assets/navigation${defaultPath}/places.jpg`);
            }
          } catch (e) {
            return '';
          }
        case '/routes':
          try {
            try {
              try {
                return require(`@/assets/navigation${regionPath}/routes.png`);
              } catch (e) {
                return require(`@/assets/navigation${regionPath}/routes.jpg`);
              }
            } catch (e) {
              return require(`@/assets/navigation${defaultPath}/routes.jpg`);
            }
          } catch (e) {
            return '';
          }
        case '/guides':
        case '/travel-agencies':
        case '/travel-agencies-and-guides':
          try {
            try {
              try {
                return require(`@/assets/navigation${regionPath}/travel-agencies-and-guides.png`);
              } catch (e) {
                return require(`@/assets/navigation${regionPath}/travel-agencies-and-guides.jpg`);
              }
            } catch (e) {
              return require(`@/assets/navigation${defaultPath}/travel-agencies-and-guides.jpg`);
            }
          } catch (e) {
            return '';
          }
        default:
          return '';
      }
    },
  },
}
</script>

<style lang="scss">
.menu {
  padding-top: 16px;
  padding-bottom: 16px;
}
</style>
